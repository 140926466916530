<template>
  <div class="ma-4">
    <v-toolbar>
      <v-toolbar-title>SERVE Admin</v-toolbar-title>
      <v-spacer />
      <v-select v-model="term" :items="terms" label="Term" outlined hide-details dense class="mt-1 mr-2" style="max-width:200px" />
    </v-toolbar>
    <v-tabs v-model="tab">
      <v-tab>Student Assigments</v-tab>
      <v-tab>Supervisors/Areas</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-toolbar>
          <v-btn-toggle v-model="filter.assignment">
            <v-btn v-for="{ assignment, count } in counts" :key="assignment" :value="assignment">
              {{ assignment }}
              <v-avatar color="#193264" class="white--text ml-2" right size="32">{{ count }}</v-avatar>
            </v-btn>
          </v-btn-toggle>
          <!-- <v-select v-model="filter.year" :items="[{ text: 'First Year', value: 'First' }, { text: 'Second Year', value: 'Second' }, { text: 'Everyone', value: '' }]" label="Year at Covenant" outlined dense hide-details class="mx-2" style="max-width:200px" /> -->
        </v-toolbar>
        <v-data-table :items="filteredStudents" :headers="headers">
          <template v-slot:item.fallAssignment="{ item }">
            <v-chip v-if="item.fallAssignment" label outlined>{{ item.fallAssignment.assignment }}</v-chip>
            <span v-else-if="item.startTerm.substring(4, 6) === '05'">**Started in Spring**</span>
          </template>
          <template v-slot:item.fallHours="{ item }">
            <v-chip v-if="item.fallAssignment" label outlined>{{ item.fallAssignment.hours || 0 }}</v-chip>
          </template>
          <template v-slot:item.springAssignment="{ item }">
            <v-chip v-if="item.springAssignment" label outlined>{{ item.springAssignment.assignment }}</v-chip>
          </template>
          <template v-slot:item.springHours="{ item }">
            <v-chip v-if="item.springAssignment" label outlined>{{ item.springAssignment.hours || 0 }}</v-chip>
          </template>
          <template v-slot:item.workstudy="{ item }">
            <v-chip v-for="{ _id, desc } of item.workstudy" :key="_id" label class="mr-1">{{ desc }}</v-chip>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <areas />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { onMounted, ref, computed, watch } from '@vue/composition-api'

export default {
  components: {
    Areas: () => import('@/components/serve/areas')
  },
  setup (props, { root }) {
    const tab = ref(0)
    const term = ref('')
    const terms = ref([])
    const counts = ref([])
    const headers = computed(() => {
      const arr = [
        { text: 'Banner ID', value: 'bannerId' },
        { text: 'Last Name', value: 'last' },
        { text: 'First Name', value: 'first' }
        // { text: 'Start Term', value: 'startTerm' }
      ]
      if (term.value.substring(4, 6) === '05') {
        const year = parseInt(term.value.substring(0, 4))
        arr.push({ text: `Fall ${(year - 1)} Assignment`, value: 'fallAssignment' })
        arr.push({ text: `Fall ${(year - 1)} Hours`, value: 'fallHours' })
        arr.push({ text: `Spring ${year} Assignment`, value: 'springAssignment' })
        arr.push({ text: `Spring ${year} Hours`, value: 'springHours' })
      } else {
        const year = parseInt(term.value.substring(0, 4))
        arr.push({ text: `Spring ${year} Assignment`, value: 'springAssignment' })
        arr.push({ text: `Spring ${year} Hours`, value: 'springHours' })
        arr.push({ text: `Fall ${(year)} Assignment`, value: 'fallAssignment' })
        arr.push({ text: `Fall ${(year)} Hours`, value: 'fallHours' })
      }
      arr.push({ text: 'Work-Study Placement', value: 'workstudy' })
      return arr
    })
    const isLoading = ref(false)
    const loadingProgress = ref(0)

    const startTerms = computed(() => {
      const terms = [term.value]
      if (term.value.substr(4) === '05') {
        const yr = parseInt(term.value.substr(0, 4))
        terms.push((yr - 1) + '60')
      } else {
        const yr = parseInt(term.value.substr(0, 4))
        terms.push((yr) + '05')
      }
      return terms
    })

    const workStudyOptions = ref([])
    const filter = ref({
      assignment: '',
      // year: '',
      workstudy: [],
      workstudyInvert: false
    })
    const students = ref([])
    const filteredStudents = computed(() => students.value.filter(({ assignments, year: y }) => {
      const { assignment } = filter.value
      if (assignment === '' || assignment == null) return true
      else if (!assignments || assignments.length === 0) return assignment === 'Not Placed'

      const rec = assignments.find(({ term: t }) => t === term.value)
      if (assignment === 'Not Placed') return !rec
      else if (rec) return rec.assignment === assignment
      return false
    }))
    async function loadStudents () {
      // Try to load the students based on the selected term; If nobody loaded, then load from the directory
      const { total } = await root.$feathers.service('serve/student').find({ query: { startTerm: term.value } })
      if (total === 0) {
        alert('The selected term has not been set up yet. Please contact Technology Services to import the new students.')
        return
      }
      let aidy = parseInt(term.value.substring(2, 4)) * 101 + 1
      if (term.value.substring(4, 6) === '05') aidy -= 101
      aidy += ''
      const aggregate = [
        { $match: { startTerm: { $in: startTerms.value } } },
        { $lookup: {
          from: 'Workstudy-Students',
          as: 'workstudy',
          let: { pidm: '$pidm' },
          pipeline: [
            { $match: { aidy, $expr: { $eq: ['$pidm', '$$pidm'] } } }
          ]
        } },
        {
          $lookup: {
            from: 'WorkStudy-Students',
            as: 'workstudy',
            let: {
              bannerId: '$bannerId'
            },
            pipeline: [
              {
                $match: {
                  aidy,
                  $expr: {
                    $eq: ['$bannerId', '$$bannerId']
                  }
                }
              },
              { $project: {
                _id: 1,
                desc: 1
              } }
            ]
          }
        },
        // { $project: {
        //   bannerId: 1,
        //   last: 1,
        //   first: 1,
        //   email: 1,
        //   startTerm: 1,
        //   // year: { $cond: {
        //   //   if: { $lt: ['$termDiff', 100] },
        //   //   then: 'First',
        //   //   else: 'Second'
        //   // } },
        //   assignments: 1
        //   workstudy: 1
        // } },
        { $sort: { last: 1, first: 1 } }
      ]
      const { data } = await root.$feathers.service('serve/student').find({ query: { aggregate } })
      for (const workstudy of data) {
        if (workstudy.length > 0) console.log(JSON.stringify(workstudy))
      }
      students.value = data.map((row) => {
        if (row.assignments) {
          row.fallAssignment = row.assignments.find(({ term }) => term.substring(4, 6) === '60')
          row.springAssignment = row.assignments.find(({ term }) => term.substring(4, 6) === '05')
        }
        return row
      })
    }
    async function loadCounts () {
      const aggregate = [
        { $match: { startTerm: { $in: startTerms.value } } },
        { $addFields: {
          assignment: { $filter: { input: '$assignments', as: 'asgn', cond: { $eq: ['$$asgn.term', term.value] } } }
        } },
        {
          $addFields: {
            assignment: { $first: '$assignment' }
          }
        },
        {
          $group: {
            _id: '$assignment.assignment',
            count: { $sum: 1 }
          }
        },
        {
          $project: {
            assignment: { $ifNull: ['$_id', 'Not Placed'] },
            count: 1
          }
        },
        { $sort: { assignment: 1 } }
      ]
      const { data } = await root.$feathers.service('serve/student').find({ query: { aggregate } })
      counts.value = data
    }
    watch(term, () => {
      if (term.value) {
        loadStudents()
        loadCounts()
      }
    })

    const areas = ref([])
    async function loadAreas () {
      const { data } = await root.$feathers.service('serve/area').find({ query: { $populate: 'supervisors' } })
      areas.value = data
    }

    onMounted(() => {
      loadAreas()
      // Load the terms for the term dropdown
      const dt = new Date()
      dt.setMonth(dt.getMonth() + 2)
      root.$feathers.service('system/term').find({ query: { term: { $gte: '202360', $regex: '(05|60)$' }, start: { $lte: dt }, $select: ['term', 'label'], $sort: { start: -1 } } }).then(({ data }) => {
        terms.value = data.map(({ term, label }) => { return { text: label, value: term } })
        term.value = data[0].term
      })
    })

    async function updateAssignment (id, placement) {
      const temp = await root.$feathers.service('serve/student').patch(id, { placement })
      const index = students.value.findIndex(({ _id }) => _id === id)
      if (index >= 0) {
        students.value.splice(index, 1, temp)
      }
    }

    return {
      tab,
      term,
      terms,
      counts,
      headers,
      isLoading,
      loadingProgress,
      workStudyOptions,
      filter,
      students,
      filteredStudents,
      loadStudents,
      areas,
      updateAssignment
    }
  }
}
</script>
