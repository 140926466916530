var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-4"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("SERVE Admin")]),_c('v-spacer'),_c('v-select',{staticClass:"mt-1 mr-2",staticStyle:{"max-width":"200px"},attrs:{"items":_vm.terms,"label":"Term","outlined":"","hide-details":"","dense":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1),_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Student Assigments")]),_c('v-tab',[_vm._v("Supervisors/Areas")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-toolbar',[_c('v-btn-toggle',{model:{value:(_vm.filter.assignment),callback:function ($$v) {_vm.$set(_vm.filter, "assignment", $$v)},expression:"filter.assignment"}},_vm._l((_vm.counts),function(ref){
var assignment = ref.assignment;
var count = ref.count;
return _c('v-btn',{key:assignment,attrs:{"value":assignment}},[_vm._v(" "+_vm._s(assignment)+" "),_c('v-avatar',{staticClass:"white--text ml-2",attrs:{"color":"#193264","right":"","size":"32"}},[_vm._v(_vm._s(count))])],1)}),1)],1),_c('v-data-table',{attrs:{"items":_vm.filteredStudents,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.fallAssignment",fn:function(ref){
var item = ref.item;
return [(item.fallAssignment)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(item.fallAssignment.assignment))]):(item.startTerm.substring(4, 6) === '05')?_c('span',[_vm._v("**Started in Spring**")]):_vm._e()]}},{key:"item.fallHours",fn:function(ref){
var item = ref.item;
return [(item.fallAssignment)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(item.fallAssignment.hours || 0))]):_vm._e()]}},{key:"item.springAssignment",fn:function(ref){
var item = ref.item;
return [(item.springAssignment)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(item.springAssignment.assignment))]):_vm._e()]}},{key:"item.springHours",fn:function(ref){
var item = ref.item;
return [(item.springAssignment)?_c('v-chip',{attrs:{"label":"","outlined":""}},[_vm._v(_vm._s(item.springAssignment.hours || 0))]):_vm._e()]}},{key:"item.workstudy",fn:function(ref){
var item = ref.item;
return _vm._l((item.workstudy),function(ref){
var _id = ref._id;
var desc = ref.desc;
return _c('v-chip',{key:_id,staticClass:"mr-1",attrs:{"label":""}},[_vm._v(_vm._s(desc))])})}}])})],1),_c('v-tab-item',[_c('areas')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }